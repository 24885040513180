<template>
    <div class="d-flex flex-column justify-space-between" style="height: 500px">
        <div class="d-flex flex-column mt-2" v-if="!dialPad">
            <v-avatar size="160" color="secondary darken-1" class="mx-auto">
                <v-icon size="100" class="white--text">mdi-account</v-icon>
            </v-avatar>
            <span class="text-h5 mx-auto mt-4">{{ phoneNumber }}</span>
            <span
                v-if="$_.get(contact, 'name', '')"
                class="text-h4 mx-auto mt-4"
                >{{ contact.name }}</span
            >
            <span
                v-if="$_.get(contact, 'type', '')"
                class="text-body-2 mx-auto mt-1 text-lighten grey--text"
                >{{ contact.type }}</span
            >
            <div class="d-flex mx-auto mt-2">
                <v-icon small class="grey--text mr-2"
                    >mdi-timer-sand-full</v-icon
                >
                <span class="grey--text lighten-1">{{ callDuration }}</span>
            </div>
        </div>

        <div v-if="dialPad" class="mt-8">
            <phone-dialer @dtmf="dtmf"></phone-dialer>
        </div>

        <div class="d-flex justify-center align-center mb-8">
            <v-tooltip top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-on="on"
                        v-bind="attrs"
                        class="mr-2"
                        color="secondary"
                        dark
                    >
                        <v-icon>mdi-volume-high</v-icon>
                    </v-btn>
                </template>
                <span>Adjust Volume</span>
            </v-tooltip>
            <v-tooltip top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-on="on"
                        v-bind="attrs"
                        class="mr-2"
                        :color="callMute ? 'grey lighten-1' : 'secondary'"
                        @click.stop="setMute"
                        dark
                    >
                        <v-icon :color="callMute ? 'error' : ''"
                            >mdi-volume-mute</v-icon
                        >
                    </v-btn>
                </template>
                <span>{{ callMute ? "Unmute" : "Mute" }}</span>
            </v-tooltip>
            <v-tooltip top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-on="on"
                        v-bind="attrs"
                        class="mr-2"
                        :color="callHold ? 'grey lighten-1' : 'secondary'"
                        @click.stop="setHold()"
                        dark
                    >
                        <v-icon :color="callHold ? 'error' : ''"
                            >mdi-phone-paused</v-icon
                        >
                    </v-btn>
                </template>
                <span>{{ callHold ? "Unhold" : "Hold" }}</span>
            </v-tooltip>
            <v-tooltip top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-on="on"
                        v-bind="attrs"
                        class="mr-2"
                        :color="callRecord ? 'grey lighten-1' : 'secondary'"
                        @click.stop="setRecord()"
                        dark
                    >
                        <v-icon :color="callRecord ? 'error' : ''"
                            >mdi-radiobox-marked</v-icon
                        >
                    </v-btn>
                </template>
                <span>{{
                    callRecord ? "Stop Recording" : "Start Recording"
                }}</span>
            </v-tooltip>
            <v-tooltip top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mr-2"
                        color="secondary"
                        v-on="on"
                        v-bind="attrs"
                        dark
                        @click="dialPad = !dialPad"
                        :depressed="dialPad"
                    >
                        <v-icon>mdi-dialpad</v-icon>
                    </v-btn>
                </template>
                <span>Dialpad</span>
            </v-tooltip>
            <v-tooltip top color="secondary" :disabled="infoDisabled">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :class="`mr-2 ${infoDisabled ? 'disabled' : ''}`"
                        :color="`secondary ${infoDisabled ? 'lighten-3' : ''}`"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop="
                            showInfo = !showInfo;
                            $emit('show:info', showInfo);
                        "
                        dark
                    >
                        <v-icon
                            :class="{
                                'secondary--text text--lighten-2': infoDisabled,
                            }"
                            >mdi-information</v-icon
                        >
                    </v-btn>
                </template>
                <span>{{ infoLabel }}</span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "call-active",
    components: {
        PhoneDialer: () => import("./PhoneDialer"),
    },
    props: {
        phoneNumber: {
            type: String,
            required: true,
        },
        info: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            dialPad: false,
            showInfo: false,
        };
    },
    computed: {
        ...mapGetters("Calls", [
            "activeCallInfo",
            "callDuration",
            "callActive",
            "callMute",
            "callHold",
            "callRecord",
            "contact",
        ]),
        infoDisabled() {
            return this.contact
                ? !["Applicant Address", "Applicant Employer"].includes(
                      this.$_.get(this.contact, "type", "")
                  )
                : true;
        },
        infoLabel() {
            switch (this.$_.get(this.contact, "type", "")) {
                case "Applicant Address":
                    return "View Rental History";
                case "Applicant Employer":
                    return "View Employment History";
                default:
                    return "View Information";
            }
        },
    },

    methods: {
        ...mapActions("Calls", [
            "mute",
            "unmute",
            "hold",
            "unhold",
            "startRecord",
            "stopRecord",
            "dtmf",
        ]),
        setMute() {
            this.callMute ? this.unmute() : this.mute();
        },
        setHold() {
            this.callHold ? this.unhold() : this.hold();
        },
        setRecord() {
            this.callRecord ? this.stopRecord() : this.startRecord();
        },
    },
};
</script>

<style scoped>
.disabled {
    pointer-events: none;
    cursor: default;
}
</style>