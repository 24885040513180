var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-space-between",
      staticStyle: { height: "500px" }
    },
    [
      !_vm.dialPad
        ? _c(
            "div",
            { staticClass: "d-flex flex-column mt-2" },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "mx-auto",
                  attrs: { size: "160", color: "secondary darken-1" }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { size: "100" } },
                    [_vm._v("mdi-account")]
                  )
                ],
                1
              ),
              _c("span", { staticClass: "text-h5 mx-auto mt-4" }, [
                _vm._v(_vm._s(_vm.phoneNumber))
              ]),
              _vm.$_.get(_vm.contact, "name", "")
                ? _c("span", { staticClass: "text-h4 mx-auto mt-4" }, [
                    _vm._v(_vm._s(_vm.contact.name))
                  ])
                : _vm._e(),
              _vm.$_.get(_vm.contact, "type", "")
                ? _c(
                    "span",
                    {
                      staticClass:
                        "text-body-2 mx-auto mt-1 text-lighten grey--text"
                    },
                    [_vm._v(_vm._s(_vm.contact.type))]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex mx-auto mt-2" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "grey--text mr-2", attrs: { small: "" } },
                    [_vm._v("mdi-timer-sand-full")]
                  ),
                  _c("span", { staticClass: "grey--text lighten-1" }, [
                    _vm._v(_vm._s(_vm.callDuration))
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialPad
        ? _c(
            "div",
            { staticClass: "mt-8" },
            [_c("phone-dialer", { on: { dtmf: _vm.dtmf } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-center align-center mb-8" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: { color: "secondary", dark: "" }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-volume-high")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Adjust Volume")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: _vm.callMute
                                  ? "grey lighten-1"
                                  : "secondary",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setMute($event)
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: _vm.callMute ? "error" : "" } },
                            [_vm._v("mdi-volume-mute")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(_vm._s(_vm.callMute ? "Unmute" : "Mute"))])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: _vm.callHold
                                  ? "grey lighten-1"
                                  : "secondary",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setHold()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: _vm.callHold ? "error" : "" } },
                            [_vm._v("mdi-phone-paused")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(_vm._s(_vm.callHold ? "Unhold" : "Hold"))])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: _vm.callRecord
                                  ? "grey lighten-1"
                                  : "secondary",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setRecord()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: _vm.callRecord ? "error" : "" } },
                            [_vm._v("mdi-radiobox-marked")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.callRecord ? "Stop Recording" : "Start Recording")
                )
              ])
            ]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: "secondary",
                                dark: "",
                                depressed: _vm.dialPad
                              },
                              on: {
                                click: function($event) {
                                  _vm.dialPad = !_vm.dialPad
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-dialpad")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Dialpad")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                color: "secondary",
                disabled: _vm.infoDisabled
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              class:
                                "mr-2 " + (_vm.infoDisabled ? "disabled" : ""),
                              attrs: {
                                color:
                                  "secondary " +
                                  (_vm.infoDisabled ? "lighten-3" : ""),
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showInfo = !_vm.showInfo
                                  _vm.$emit("show:info", _vm.showInfo)
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            {
                              class: {
                                "secondary--text text--lighten-2":
                                  _vm.infoDisabled
                              }
                            },
                            [_vm._v("mdi-information")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(_vm._s(_vm.infoLabel))])]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }